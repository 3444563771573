<template>
    <div class="step1 step2">
        <div class="title">
            {{
            this.$i18n.locale == 'ar' ?
            'اختر التفاصيل التي تناسب باقتك'
            :
            'Choose the details that suit your package'
            }}
        </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card-counter">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="title-counter">
                    {{ $t('Number of Employees') }}
                  </div>
                  <div class="count">
                    <span @click="decrease">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="8" y="14" width="2" height="14" rx="1" transform="rotate(-180 8 14)" fill="#F3F7F9"/>
                        <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#0A3041"/>
                      </svg>
                    </span>
                    <input onkeypress="if ( isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;"
                    v-model="$store.state.packageOrder.step2.NumberEmployees" type="number" class="count-val">
                    <span @click="increase">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#0A3041"/>
                        <rect x="8" y="14" width="2" height="14" rx="1" transform="rotate(-180 8 14)" fill="#0A3041"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="title-counter">
                    {{ $t('Number of commercial records') }}
                  </div>
                  <div class="count">
                    <span @click="decreaseRecord">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="8" y="14" width="2" height="14" rx="1" transform="rotate(-180 8 14)" fill="#F3F7F9"/>
                        <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#0A3041"/>
                      </svg>
                    </span>
                    <input onkeypress="if ( isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;"
                    v-model="$store.state.packageOrder.step2.AdditionalRecord" type="number" class="count-val">
                    <span @click="increaseRecord">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#0A3041"/>
                        <rect x="8" y="14" width="2" height="14" rx="1" transform="rotate(-180 8 14)" fill="#0A3041"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <div>
              <select class="bg-white" v-model="total" @change="handleChange">
            <option value="0">{{ $t('Payment plan') }}</option>
            <option :value="annual_price">{{ $t('Annual') }}</option>
            <option :value="semi_annual_price">{{ $t('Biannual') }}</option>
            <option :value="quarterly_price">{{ $t('Quarterly') }}</option>
            <option :value="monthly_price">{{ $t('Monthly') }}</option>
          </select>
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="card-packge-info">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="name">
                    {{ $t('Price') }}
                  </span>
                  <span class="value">
                    {{total}} {{$t('SAR')}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between" v-if="discount != 0">
                  <span class="name">
                    {{ $t('Discount') }}
                  </span>
                  <span class="value">
                    {{ discount }} {{$t('SAR')}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between" v-if="tax != 0">
                  <span class="name">
                    {{ $t('Tax') }}
                  </span>
                  <span class="value">
                    {{ tax }} {{$t('SAR')}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="name">
                    {{ $t('Total') }}
                  </span>
                  <span class="value" v-if="discount == 0 && tax == 0">
                    {{ total }} {{$t('SAR')}}
                  </span>
                  <span class="value" v-if="discount != 0 && tax == 0">
                    {{ total - (total*discount) }} {{$t('SAR')}}
                  </span>
                  <span class="value" v-if="discount == 0 && tax != 0">
                    {{ total+(tax * total) }} {{$t('SAR')}}
                  </span>
                  <span class="value" v-if="discount != 0 && tax != 0">
                    {{ total + (tax * total) - (total * discount) }} {{$t('SAR')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="Attributes?.length > 0">
              <div :class="[item.type == 'text' || item.type == 'select' ? 'col-md-6':'col-md-12']"
              class=" mb-3" v-for="(item,index) in Attributes" :key="index">
                <div v-if="item.type == 'text'">
                  <label class="title mb-1">{{ item.name }}</label>
                  <input @keydown="handleKeydown" v-model="$store.state.packageOrder.step2.atrrbuites[index].attribute_value"
                  :type="item.type" :placeholder="item.name">
                </div>
                <div v-if="item.type == 'select'">
                  <label class="title mb-1">{{ item.name }}</label>
                  <select @keydown="handleKeydown" v-model="$store.state.packageOrder.step2.atrrbuites[index].attribute_value_id" :name="item.name" id="">
                    <option :value="j.id" v-for="j in item.attribute_values">
                    {{ j.value }}
                    </option>
                  </select>
                </div>
                <div v-if="item.type == 'file'">
                <label class="imageId" for="imageId">
                <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.45645 12.6206C4.46713 13.5696 1.5 17.157 1.5 21.4375C1.5 26.4426 5.55656 30.5 10.5606 30.5C11.419 30.5 12.2495 30.3806 13.0364 30.1575M30.5435 12.6206C34.5329 13.5696 37.5 17.157 37.5 21.4375C37.5 26.4426 33.4435 30.5 28.4394 30.5C27.581 30.5 26.7505 30.3806 25.9636 30.1575M30.4939 12.375C30.4939 6.3689 25.626 1.5 19.6212 1.5C13.6164 1.5 8.74847 6.3689 8.74847 12.375M13.3382 19.5173L19.6213 13.2122L26.0832 19.6252M19.6212 28.6873V16.4184"
                        stroke="#225476" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>
                    {{ $t('List your files') }}
                    <br>
                   {{ item.validation_rules[2].slice(5,item.validation_rules[2].length) }}
                </span>
            </label>
            <input @keydown="handleKeydown" accept="image/*,application/pdf" type="file" id="imageId" for="imageId" hidden
                @change="setfileIndex(index),SetimageId()">
                </div>
              </div>
            </div>
          </div>
    </div>
    </template>
<script>
export default {
  data() {
    return {
      total:0,
      imageId:[],
      itemSelected:[],
      ShowCompany:false,
      fileIndex : 0,
      atrrbuites:{
        1:{

        },
        2:{
        }
      },
      items:[
        {
          id:1,
          name:'هذا النص مثال لاسم الشركة',
          commercial:"235846975"
        },
        {
          id:2,
          name:'هذا النص مثال لاسم الشركة',
          commercial:"235846975"
        }
      ]
    }
  },
  props:['annual_price','semi_annual_price','quarterly_price','monthly_price','discount','tax','Attributes'],
  methods:{
    handleKeydown(event) {
          if (event.key === 'Tab') {
            event.preventDefault(); // Prevent the default tab action
          }
        },
    handleChange(event) {
      const selectedValue = event.target.value;
      let type = '';

      switch (selectedValue) {
        case this.annual_price.toString():
          type = 'annual';
          break;
        case this.semi_annual_price.toString():
          type = 'semi-annual';
          break;
        case this.quarterly_price.toString():
          type = 'quarterly';
          break;
        case this.monthly_price.toString():
          type = 'monthly';
          break;
        default:
          type = ''; // No type for zero or unselected
          break;
      }

      // Set the payment plan
      this.SetPaymentPlan(selectedValue, type);
    },

    SetPaymentPlan(amont,type){
      this.$store.state.packageOrder.step2.total =  amont;
      this.$store.state.packageOrder.step2.paymenytPlanType = type;
    },
    setfileIndex(index){
      this.fileIndex = index
    },
    SetimageId(e){
      console.log(event.target.files[0]);
      console.log(this.fileIndex);
      this.$store.state.packageOrder.step2.atrrbuites[this.fileIndex].value = event.target.files[0]
    },
    decrease(){
      if( this.$store.state.packageOrder.step2.NumberEmployees != 0){
        this.$store.state.packageOrder.step2.NumberEmployees--
      }
    },
    increase(){
        this.$store.state.packageOrder.step2.NumberEmployees++;

    },
    decreaseRecord(){
      if( this.$store.state.packageOrder.step2.AdditionalRecord != 0){
        this.$store.state.packageOrder.step2.AdditionalRecord--
      }
    },
    increaseRecord(){
        this.$store.state.packageOrder.step2.AdditionalRecord++;
    },
  },

};
</script>
